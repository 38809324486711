.addForm {
    padding: 2rem;
}

.form-input {
    margin: 10px 0px;
}

h2, textarea{
    text-align: center;
}

button {
    background-color: #007bff;
    color: #fff;
    padding: 0.375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
}

.lineContainer{
    max-width: 400px;
    text-align: left;
    margin: 0 auto;
    background: #fbe8a6;
    border-radius: 5px;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    min-height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
}
.locButton {
    background-color: #007bff;
    color: #fff;
    padding: 0rem .75rem;
    font-size: 2rem;
    line-height: 1.5;
    width: auto;
    border-radius: 0.25rem;
    cursor: pointer;
}

.inputField{
    margin: 0.5rem;
}

#container {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
  }