.addForm {
    padding: 0.2rem;
}

.form-input {
    margin: 10px 0px;
}

h2, textarea{
    text-align: center;
}

button {
    background-color: #007bff;
    color: #fff;
    padding: 0.375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
}

.smallInputField{
    width: 70%;
    border-radius: 20px;
    align-items: left;
    align-content: left;
}

.addProdPlusButton{
    border: black;
    border-width: 1px;
    border-style: solid;
    font-size: 16px;
    width: 30%;
    height: 30%;
    border-radius: 100%;
    background-color: lightslategrey;
}

.rowContainer{
    text-align: center;
}

.submitButton {
    text-align: center;
}

p {
    text-align: center;
}
.valueInput{
    margin: 0px;
}

.lotRow{
    display: flex;
    padding: 2px 5px;
}

.lotAdjustInput{
    font-size: 14px;
    border-radius: 20px;
    width: 10rem;
    align-content: right;
    align-items: right;
    display: fixed;
}

.locationSelect{
    margin-left: 4rem;
    align-items: right;
    text-align: right;
    height: 3rem;
}

.eventTable{
    align-items: center;
}

.tableCells{
    max-width: 3rem;
    word-wrap: break-word;
}