.smaller-input {
  font-size: 12px;
  width: 7rem;
  height: 1rem;
}

.fieldTitle{
  text-align: left;
  margin-left: 2rem;
}

.freeze-table{
  border-spacing: 0;;
  padding: 0;
  border: 1px;
}

thead th{
  top:0;
  position:sticky;
  z-index: 20;
}

td{
  min-width:150px !important;
  border: 1px solid #221111;
}

th, td{
  outline-offset: -1px;
}
.col-id-no{
  left:0;
  background-color: white;
  position: sticky;
}

  .fixed{
    border-spacing: 0;
  }
  .fixed thead tr{
    background-color:#f07e7e;
    height: 20px;
  }
  .scroll{
    width: 400px;
    overflow-x: auto;
  }
.container{
  overflow: auto;
  width: 400px;
  display:flex;
}

/*
.container{
    width: auto;
    display:flex;
  }
  .scroll{
    width: 400px;
    overflow-x: scroll;
  }
  .fixed{
    border-spacing: 0;
  }
  .fixed thead tr{
    background-color:#f07e7e;
    height: 30px;
  }
  .scrollable thead tr{
    left:0;
    background-color:#f07e7e;
    height: 30px;
  }

  .scrollable{
    width:100px;
    border-spacing: 0;
  }
  td{
    min-width:150px !important;
    border: 1px solid #221111;
  }
  
.tableHeader {
    background-color: aqua;
    padding: 10px;
    margin: 5, 0;
}
*/
