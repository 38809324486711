form {
    padding: 20px 0;
    position: relative;
  }
  form input {
    border: 1px solid rgba(252, 252, 252, 0.6);
    background-color:#d2fdff;
    width: 250px;
    border-radius: 3px;
    font-family: "Source Sans Pro", sans-serif;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    color: #303c6c;
    font-weight: 300;
  }
  
  form button {
    appearance: none;
    outline: 0;
    background-color: white;
    border: 0;
    padding: 10px 15px;
    margin: 0 auto 0 auto;
    display: block;
    color: #303c6c;
    border-radius: 3px;
    width: 280px;
    font-size: 18px;
  }
  
  .prompt{
    text-align: center;
  }