.addForm {
    padding: 2rem;
}

.form-input {
    margin: 10px 0px;
}

h2, h3, textarea{
    text-align: center;
}

.centerDiv{
    text-align: center;
    align-items: center;
}


.quickInv{
    border-style: inset;
    border-color: black;
    border-width: 1mm;
    border-radius: 15px;
    text-align: center;
}

.plusButton{
    border-radius: 50%;
    background-color: lightslategrey;

}

.eventInfo{
    font-size: 2rem;
}

.button {
    border-style: solid;
    border-color: black;
    background-color: lightslategrey;
    color: #fff;
    padding: 0.5rem .75rem;
    font-size: 1.75rem;
    line-height: 1.5;
    border-radius: 9px;
    cursor: pointer;
}

.smallBorder {
    border-width: 1px;
}

.quickSelect {
    border-style: solid;
    border-color: black;
    background-color: lightslategrey;
    color: #fff;
    padding: 0.5rem .75rem;
    font-size: 1.75rem;
    line-height: 1.5;
    border-radius: 9px;
    cursor: pointer;
    max-width: 125px;
}

p {
    text-align: center;
}

