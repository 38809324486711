.listItem {
    max-width: 400px;
    margin: 0 auto;
    background: #fbe8a6;
    border-radius: 5px;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    min-height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
}

.listItem a{
    color: #303c6c;
    text-decoration: none;
}

.listItem a:hover,
.listItem a:active{
    color: #ffffff;
}
.descriptionObject{
    text-align: center;
    margin-left: 20px;
    align-items: right;
}

.titleLink{
    padding: 0 1rem 1rem 1rem; 
}

.deleteBut{
    border-radius: 50%;
    align-content: right;
}

.form-input{
    margin: 10
}