:root {
  --background-color: #d2fdff;
  --text-primary: #BB86FC;
  --border-color:#BB86FC;
} 

[data-theme="light"] {
  --background: #d2fdff;
  --background-color: #d2fdff;
  --text-primary: #000;
  --border-color:green;
  --body-background: #d2fdff;
  --toolbar-background: #fbe8a6;
}

[data-theme="dark"] {
  --background: #121212;
  --background-color: #121212;
  --text-primary: #BB86FC;
  --font-color:#ffffff;
  --border-color:#ffffff;
}

.app{
  background: var(--background);
  background-color: var(--background-color);
  color: var(--text-primary);
  border-color: var(--border-color);
}

.toolbar{
  background: var(--toolbar-background);
}

.toolbar_navigation-items a{
  color: var(--text-primary);
  text-decoration: none;
}


body {
  margin: 0;
  background-color: var(--background-color);
}
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d2fdff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageTitle {
  text-align: center;
  color: #303c6c;
  margin-left: 1rem;
}*/
