.customRowContainer{
    height: auto;
    width: auto;
    display: flex;
    align-content: center;
}


.addForm {
    padding: 0rem;
}

.form-input {
    margin: 10px 0px;
}

h2, textarea{
    text-align: center;
}

.inputField{
    border-radius: 20px;
}

.smallInputField{
    width: 70%;
    border-radius: 20px;

}


.addFormCustom{

    border-color: coral;
}

.addProdPlusButton{
    border: black;
    border-width: 1px;
    border-style: solid;
    font-size: 16px;
    width: 2rem;
    height: 2rem;
    border-radius: 10%;
    background-color: blue;
}

.addIconButton{
    border: black;
    border-width: 1px;
    border-style: solid;
    font-size: 16px;
    width: auto;
    height: 75%;
    border-radius: 100%;
    background-color: lightslategrey;
}
#selectAdd{
    margin-left: 2rem;
}
.selectField{
    margin-left: 3rem;
    margin-right: 0;
    height: 3rem;
}


button {
    background-color: #007bff;
    color: #fff;
    padding: 0.375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
}

.locButton {
    background-color: #007bff;
    color: #fff;
    padding: 0rem .5rem;
    font-size: 2rem;
    line-height: 1.5;
    width: auto;
    border-radius: 100%;
    cursor: pointer;
}

/*
.submitButton{
    border-radius: 4rem;
    width: 88%;
}*/

#container {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
  }

.locationSelect{
    align-items: center;
    text-align: center;
    
}