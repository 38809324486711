.rowItems {
    white-space: nowrap;
    display: flex;
    
}

.addProdButton{
    border-style: solid;
    font-size: 16px;
    width: auto;
    height: 100%;
    border-radius: 100%;
}


.qtyInputField{
    padding: 0.5rem;
    width: 10rem;
}