

.container-fluid {
    text-align: center;
  }
Header{
  font-size: 26px;
}
.Waretable{
  font-size: 206px;
  border: 1px solid black;
  padding:0 28px;
}

tr, td, th{
  text-align: center;
  border: 1px solid black;
}
.lotTable{
    align-items: center;
    text-align: center;
    padding:0 28px;
}
.fixed thead tr{
  background-color:#f07e7e;
  height: 20px;
}

.exitingPrompt{
  text-align: center;
  font-size: medium;
  font-weight: bold;
  align-items: center;
}

.exitButton{
  font-size: medium;
  color: black;
  background-color: red;
  border-radius: 25%;
}
.stayButton{
  font-size: medium;
  color: black;
  background-color: green;
  border-radius: 25%;
}