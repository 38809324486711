.col-md-12{
    padding: 1rem;
}

.dashContainer{
   width: auto;
}

.exitingPrompt{
    text-align: center;
    font-size: medium;
    font-weight: bold;
    align-items: center;
}

.exitButton{
    font-size: medium;
    color: black;
    background-color: red;
    border-radius: 25%;
}
.stayButton{
    font-size: medium;
    color: black;
    background-color: green;
    border-radius: 25%;
}

.div{
    background-color: black;}