.toolbar{
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
}

.toolbar_navigation{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.toolbar_logo {
    margin-left: 1rem;
    font-weight: bold;

}

.toolbar_logo a {
    color: #303c6c;
    text-decoration: none;
    font-size:2.5rem;
}

.spacer {
    flex: 1;
}

.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 1.5rem;
}

.toolbar_navigation-items li{
    padding: 0 0.5rem;
}

/*
.toolbar_navigation-items a{
    color: #303c6c;
    text-decoration: none;
}*/
/*
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
    color: #fbe8a6;
}
*/

@media (max-width: 769px) {
    .toolbar_navigation-items{
        display: none;
    }
}

@media (min-width:769px){
    .toolbar_toggle-button{
        display: none;
    }
    .toolbar_logo{
        margin-left:0;
    }
}
